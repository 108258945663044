<template>
  <AppMainContainer
    @isScroll="(val) => {isScroll = val}"
  >
    <template
      #subheader
    >
      <PageHeader />
    </template>

    <template
      v-if="getActiveOffice"
      #center
    >
      <v-toolbar
        dense
        style="background-color: transparent"
        elevation="0"
      >
        <v-btn
          text
          @click="gotback"
        >
          <v-icon>mdi-arrow-left</v-icon>
          <span class="ml-1">Назад</span>
        </v-btn>
      </v-toolbar>
      <OfferForm
        v-if="hasRegion"
        :office="getActiveOffice"
        :offers="[]"
        style="padding: 30px"
        @close="currentModal = ''"
        @addBalance="onShowBuyModal"
        @buyOffer="updateOfficeAndOffer"
      />
      <p
        v-if="!hasRegion"
        style="color: red; text-align: center; padding: 8px"
      >
        У вашего объекта не назначен регион. <br>
        Для отображения вакансии при создании офферов, необходимо чтобы объект был прикреплен к региону
      </p>
    </template>
  </AppMainContainer>
</template>


<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import OfferForm from '@/components/offer/OfferlForm'
import { routeList } from '@/router/office'
import officeApi from '@/mixins/office/officeApi'
import PageHeader from '@/components/subheaders/PageHeader.vue'

const modalName = Object.freeze({
  removeOffer: 'removeOffer',
  createOffer: 'createOffer',
})

export { modalName }

export default {
  name: 'PageCreateOffer',
  components: {
    PageHeader,
    OfferForm,
    AppMainContainer,
  },
  mixins: [officeApi],
  data () {
    return {
      isScroll: true,
      isLoading: false,
      currentLoader: null,
      modalName
    }
  },
  computed: {
    hasRegion () {
      if (process.env.VUE_APP_ENABLE_VACANCY_MULTI_REGION === '1') {
        return (!!this.getActiveOffice && !!this.getActiveOffice.region && !!this.getActiveOffice.region.id)
      }
      return true
    },
  },
  watch: {

  },
  beforeDestroy () {
    this.$store.commit('offer/clearOfficeItems')
  },
  created () {
    this.$store.dispatch('temperature/fetchTemperatureList')

    if (!this.getActiveOffice || !this.getActiveOffice.id) {
      this.$router.push({ name: routeList.HOME_ROUTE_NAME })
    }
  },
  methods: {
    onShowBuyModal () {
      this.$store.dispatch('popup/showModal', { key: 'showOfferBuyForm' })
    },
    fetchOffice () {
      this.$store.dispatch('office/fetchOfficeById', { officeId: this.getActiveOffice.id })
    },
    fetchOfferByOffice (page, size) {
      return this.$store.dispatch('offer/fetchOffersListByOffice',
        { ...this.filters, page, size }).then((response) => {
        this.currentLoader = null

        return response
      })
    },
    updateOfficeAndOffer (showLoader = true) {
      this.fetchOffice()
      this.fetchOfferByOffice(showLoader)
    },
    gotback () {
      this.$router.push({
        name: routeList.OFFICE_OFFER_LIST_NAME,
        params: { id: this.getActiveOffice.id },
      })
    }
  }
}
</script>

<style type="text/scss" scoped>

.offer-grid-item{
  border: 1px solid red;
}
</style>
