<template>
  <v-container style="background-color: white">
    <p
      v-if="!hasRegion"
      style="color: red; text-align: center; padding: 8px"
    >
      У вашего объекта не назначен регион. Для отображения вакансии необходимо чтобы объект был прикреплен к региону
    </p>
    <OfferVacancySelect
      :form.sync="form"
      :validationFields="validationFields"
    />

    <v-divider
      v-if="form.executorAssignmentType"
      class="my-3"
    />

    <OfferDatesSelect
      v-if="form.executorAssignmentType"
      :startDate.sync="startDate"
      :endDate.sync="endDate"
      :dayDiff.sync="dayDiff"
      :isPeriod.sync="isPeriod"
      :startPeriod.sync="startPeriod"
      :endPeriod.sync="endPeriod"
      :shiftHours.sync="shiftHours"
      :shiftHoursFloat="shiftHoursFloat"
      :lunchBreak.sync="lunchBreak"
      :vaccine-requirement.sync="vaccineRequirement"
      :min-start-date="minStartDate"
      :min-relay-duration="minRelayDuration"
      :max-relay-duration="maxRelayDuration"
      :min-break-duration="minBreakDuration"
      :max-break-duration="maxBreakDuration"
      :form.sync="form"
      :shift-full-duration="getShiftFullDuration"
      :shift-duration-full-filled="shiftDurationFullFilled"
      @onDateChange="onDateChange"
      @onCalendarModeChange="onCalendarModeChange"
    />

    <OfferAdditionalSettings
      v-if="form.executorAssignmentType"
      :form.sync="form"
      :is-personal="isPersonal"
    />

    <v-divider
      v-if="form.executorAssignmentType"
      class="my-3"
    />
    <v-row
      no-gutters
    >
      <v-col
        cols="12"
        :md="8"
        :sm="12"
        class="py-0 my-0"
      >
        <v-row no-gutters>
          <v-col
            cols="12"
            :md="4"
            :sm="12"
            class="py-0 my-0"
          >
            <div class="modal__result-group info-form">
              <span class="modal-text modal-text--text-gray">
                {{ $t('offer.creation.hours_payed') }}
              </span>
              <h2
                v-if="form.executorAssignmentType"
                class="text-result reserv-value"
              >
                {{ shiftHours }} мин
              </h2>
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="4"
            :sm="12"
            class="py-0 my-0"
          >
            <div class="modal__result-group info-form">
              <span class="modal-text modal-text--text-gray">
                Физлица <br>(с налогами)
              </span>
              <h2
                v-if="form.executorAssignmentType"
                class="text-result reserv-value"
                :class="{'text-result_not-have': calcDifferencePrice > 0}"
              >
                {{ getExpense }} &#8381;
              </h2>
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="4"
            :sm="12"
            class="py-0 my-0"
          >
            <div class="modal__result-group info-form">
              <span class="modal-text modal-text--text-gray">
                <!--              {{ $t('offer.creation.tax') }}-->
                Самозанятые
              </span>
              <h2
                v-if="form.executorAssignmentType"
                class="text-result nalog-value"
                :class="{'text-result_not-have': calcDifferencePrice > 0}"
              >
                {{ getSelfEmployedPayment }} &#8381;
              </h2>
            </div>
          </v-col>
          <v-col
            cols="12"

            :sm="12"
            class="py-0 my-0"
          >
            <div
              v-if="calcDifferencePrice > 0"
              class="modal-text modal__offer__text-error text-center mt-3"
            >
              {{ $t('offer.creation.not_enough_funds') }}:
              <span class="modal__offer__text-error__count">
                {{ calcDifferencePrice }} &#8381;
              </span>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        :md="4"
        :sm="12"
        class="py-0 my-0"
      >
        <div
          class="modal__result-group pb-3"
        >
          <div
            v-if="form.executorAssignmentType"
            style="position: relative"
            class="modal-text offer__form__employee"
          >
            <span class="mr-2">
              {{ $t('offer.creation.staff_number_required') }}
            </span>
            <div
              class="employee-number-btns"
            >
              <IconMinus
                v-show="!isPersonal"
                class="mr-2"
                @click="changeCountEmpLoyee(-1)"
              />
              <div class="count">
                {{ form.laborerNumber }}
              </div>
              <div style="position: absolute; bottom: 0; left: 0;top: 20px;right: 0">
                <div
                  v-if="isPeriod && !myOrganizationIsOfferLess"
                  style="width: 100%; margin: auto; text-align: left; color: orange"
                >
                  {{ `Офферов требуется:` }}
                  <span style="display: inline-block; margin-left: 10px">
                    {{ `${requiredLaborer}` }}
                  </span>
                </div>
              </div>
              <IconPlusSmall
                v-show="!isPersonal"
                class="ml-2"
                @click="changeCountEmpLoyee(1)"
              />
            </div>

            <!--            <div-->
            <!--              v-if="requiredOffers"-->
            <!--              class="floating-notification"-->
            <!--            >-->
            <!--              <div style="position: relative">-->
            <!--                <div style="display: block">-->
            <!--                  {{ $t('offer.creation.offers_required') }}-->
            <!--                </div>-->
            <!--                <v-btn-->
            <!--                  class="success mt-1"-->
            <!--                  rounded-->
            <!--                  small-->
            <!--                  @click.native="onRefillOffer"-->
            <!--                >-->
            <!--                  {{ $t('offer.creation.top_up') }}-->
            <!--                </v-btn>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <v-alert
            v-if="getAccessLaborerLimitWarningMessage"
            outlined
            color="warning"
            dense
            class="py-0 mt-1 mb-0"
          >
            <span
              class="text-center"
              style="font-size: small"
            >
              {{ getAccessLaborerLimitWarningMessage }}
            </span>
          </v-alert>
          <AppLoader v-if="isLoading" />
          <v-btn
            v-if="!isLoading"
            rounded
            :disabled="!isFormValid"
            class="mt-3 success px-0 !important"
            @click.native="submitForm"
          >
            <span style="text-transform: none">{{ $t('offer.creation.publish_offer') }}</span>
          </v-btn>
        </div>
      </v-col>
      <v-col
        cols="12"
        :sm="12"
        class="py-0 my-0"
      >
        <v-alert
          v-if="errorDisplayed"
          prominent
          type="error"
        >
          <v-row align="center">
            <v-col class="grow">
              <p
                v-if="errorDisplayed && errorMessage"
                class="py-0 my-0 text-center"
              >
                {{ errorMessage }}
              </p>
            </v-col>
            <v-col
              v-if="requiredOffers"
              class="shrink"
            >
              <v-btn
                rounded
                @click="onRefillOffer"
              >
                {{ $t('offer.creation.top_up') }}
              </v-btn>
              <!--              <v-btn-->
              <!--                v-if="requiredOffers"-->
              <!--                rounded-->
              <!--                class="mt-3 success px-0 !important"-->
              <!--                @click.native="onRefillOffer"-->
              <!--              >-->
              <!--                <span style="text-transform: none"> {{ $t('offer.creation.top_up') }}</span>-->
              <!--              </v-btn>-->
            </v-col>
            <v-col
              v-else-if="!executorIsBoarded"
              class="shrink"
            >
              <v-btn
                rounded
                @click="goToSupportPage"
              >
                {{ 'К поддержке' }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OfferVacancySelect from '@/components/offer/OfferVacancySelect'
import OfferAdditionalSettings from '@/components/offer/OfferAdditionalSettings'
import OfferDatesSelect from '@/components/offer/OfferDatesSelect'
import IconMinus from '@/components/common/icons/IconMinus'
import IconPlusSmall from '@/components/common/icons/IconPlusSmall'
import { isNonNull } from '@/utils/validation'
import taxMixin from '@/mixins/taxMixin.vue'
import AppLoader from '@/components/AppLoader'
import AppWorkerTypeUtil from '@/utils/worker-type-util'
import {
  ALIAS_OEC_FAVORITE,
  ALIAS_OEC_LABORER,
  ALIAS_OEC_PERSONAL, ALIAS_OEC_REMOTE_STAFF,
  ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
  ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST,
  ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL,
  ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE,
  ALIAS_VACANCY_TYPE_INNER_WORKER
} from '@/components/offer/offer-type-view'
import routerList from '@/router/labels'
// import AppLogger from '@/utils/appLogger'

// import { pluriaizeWorkers } from '@/utils/plural-util'
// import moment from 'moment-timezone'

const REQUIRED_QR_CODE_ID = 'qr-code'

export default {
  name: 'OfferModalForm',
  components: {
    OfferVacancySelect,
    OfferDatesSelect,
    OfferAdditionalSettings,
    // OfferResult,
    IconPlusSmall,
    IconMinus,
    AppLoader,
  },
  mixins: [taxMixin],
  props: {
    office: {
      type: Object
    },
    offers: {
      type: Array,
    },
  },
  data (vm) {
    return {
      minStartDateInterval: null,
      minRelayDuration: null,
      maxRelayDuration: null,
      minBreakDuration: null,
      maxBreakDuration: null,
      minStartDate: undefined,
      vaccineRequirement: {
        value: 'not',
        optionList: [
          { id: 'not', title: 'Не нужна', value: 'not' },
          { id: REQUIRED_QR_CODE_ID, title: 'Нужна', value: REQUIRED_QR_CODE_ID },
        ]
      },
      isPeriod: false,
      startPeriod: null,
      dayDiff: 1,
      endPeriod: null,
      accessLaborerLimitWarning: null,
      isLoading: false,
      errorDisplayed: false,
      errorMessage: null,
      localFormat: 'YYYY-MM-DD[T]HH:mm:ss',
      currentLoader: null,
      shiftHours: null,
      lunchBreak: '',
      startDate: null,
      endDate: null,
      form: {
        executorAssignmentType: null,
        executorCategory: null,
        offerAccessType: null,
        vacancySelected: null,
        costPerHour: '',
        executor: null,
        selfEmployerCostPerHour: '',
        temperature: 'normal',
        gender: '-1',
        hasTransportation: false,
        freeFood: false,
        laborerNumber: 1,
        minPay: 0,
        minSelfEmployerPay: 0,
        maxSelfEmployerPay: null,
        maxPay: null,
        isPrivate: null,
      },
      validationFields: {
        // vacancySelected: [
        //   { text: '', valid: false, validator: isNonNull }
        // ],
        costPerHour: [
          {
            text: '', valid: false,
            validator: (value) => {
              return (
                parseFloat(value) >= parseFloat(vm.form.minPay) &&
                parseFloat(value) <= parseFloat(vm.form.maxPay)
              )
            }
          },
          { text: '', valid: false, validator: isNonNull },
        ],
        selfEmployerCostPerHour: [
          {
            text: '', valid: false,
            validator: (value) => {
              return (
                parseFloat(value) >= parseFloat(vm.form.minSelfEmployerPay) &&
                parseFloat(value) <= parseFloat(vm.form.maxSelfEmployerPay)
              )
            }
          },
          { text: '', valid: false, validator: isNonNull },
        ],
        temperature: [
          { text: '', valid: true, validator: isNonNull }
        ],
      },
    }
  },
  computed: {
    requiredOffers () {
      if (this.myOrganizationIsFinanceLess || this.myOrganizationIsOfferLess) {
        return false
      }

      return this.office.availableOffersNumber < this.requiredLaborer || !this.office.availableOffersNumber
    },
    executorIsBoarded () {
      const { executor } = this.form
      return !!executor && !!executor.id && !!executor.activeRelay
    },
    getAccessLaborerLimitWarningMessage () {
      // female
      if (this.isPersonal) {
        return 'Не более одного работника'
      }
      if (this.isRemoteTeam) {
        return `У вас своих работников: ${this.getPrivateAvailableWorkerNumber}`
      }
      if (this.isFavoriteTeam) {
        return `У вас избранных работников: ${this.getFavoriteTeamAvailableWorkerNumber}`
      }

      return null
    },

    getPrivateAvailableWorkerNumber () {
      const gender = this.form.gender

      let length = this.orgPrivateStaffCounter
      if (gender && gender === 'male') {
        length = this.orgPrivateMaleStaffCounter
      } else if (gender && gender === 'female') {
        length = this.orgPrivateFemaleStaffCounter
      }

      return length
    },

    getFavoriteTeamAvailableWorkerNumber () {
      const gender = this.form.gender

      let length = this.getOfficeFavoriteWorkers
      if (gender && gender === 'male') {
        length = this.getOfficeFavoriteMaleWorkers
      } else if (gender && gender === 'female') {
        length = this.getOfficeFavoriteFemaleWorkers
      }

      return length
    },

    isPersonal () {
      if (!this.form.executorAssignmentType) {
        return false
      }
      return this.form.executorAssignmentType.id === ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE
    },
    isFavoriteTeam () {
      if (!this.form.executorAssignmentType) {
        return false
      }
      return this.form.executorAssignmentType.id === ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST
    },
    isRemoteTeam () {
      if (!this.form.executorAssignmentType) {
        return false
      }
      return this.form.executorAssignmentType.id === ALIAS_VACANCY_TYPE_INNER_WORKER
    },
    getShiftFullDuration () {
      const val = this.lunchBreak
      const lunchValue = parseInt(`${val}`, 10)
      let lunchValueHours = 0
      if (lunchValue) {
        lunchValueHours = lunchValue / 60
      }
      return this.shiftHoursFloat + lunchValueHours
    },
    shiftDurationFullFilled () {
      return this.getRelayMaxDuration < this.getShiftFullDuration
    },
    currentUser () {
      return this.$store.state.users.info
    },
    hasWorkerType () {
      if (!this.currentUser) {
        return false
      }

      return !!this.currentUser.organization && !!this.currentUser.organization.workerType
    },
    getWorkerType () {
      if (!this.hasWorkerType) {
        return undefined
      }

      return this.currentUser.organization.workerType
    },
    isSelfEmployedWorkerType () {
      return this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_RU_SELF_EMPLOYED
    },
    isSimpleWorkerType () {
      return this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_LEGAL_PERSON
    },
    acceptAllWorkers () {
      return !this.getWorkerType || this.getWorkerType === AppWorkerTypeUtil.ALIAS_WORKER_TYPE_ALL
    },
    hasVaccineRequirement () {
      return this.vaccineRequirement.value
    },
    requiredLaborer () {
      return parseInt(this.form.laborerNumber) * this.getWorkDays
    },
    shiftHoursFloat () {
      if (!this.shiftHours) {
        return 0
      }
      const [hours, minutes] = this.shiftHours.split(':')
      return parseFloat((parseInt(minutes, 10) / 60).toFixed(2)) + parseInt(hours, 10)
    },
    computedResultPrice () {
      if (!this.form.costPerHour) {
        return 0
      }

      return (parseInt(this.form.laborerNumber) * parseFloat(this.form.costPerHour) * this.shiftHoursFloat)
    },
    currentOffice () {
      return this.$store.state.office.userOffice
    },
    hasRegion () {
      if (process.env.VUE_APP_ENABLE_VACANCY_MULTI_REGION === '1') {
        return (!!this.getActiveOffice && !!this.getActiveOffice.region && !!this.getActiveOffice.region.id)
      }
      return true
    },
    getWorkDays () {
      const workDay = this.isPeriod ? this.dayDiff : 1
      return workDay
    },
    getGain () {
      if (!this.form.costPerHour) {
        return 0
      }

      return this.calculateGain(this.shiftHoursFloat)
    },

    calcDifferencePrice () {
      if (this.myOrganizationIsFinanceLess) {
        return -1
      }
      if (!this.form.costPerHour || !this.form.laborerNumber) {
        return 0
      }

      return (Math.max(this.getSelfEmployedPayment, this.getExpense) - this.office.amount).toFixed(2)
    },

    getSelfEmployedPayment () {
      if (!this.form.selfEmployerCostPerHour || !this.form.selfEmployerCostPerHour || !this.form.laborerNumber) {
        return 0
      }
      const payment = parseInt(this.form.laborerNumber) * (
        parseInt(this.form.selfEmployerCostPerHour) * this.shiftHoursFloat + this.getGain
      )

      return (payment * this.getWorkDays).toFixed(2)
    },
    getExpense () {
      if (!this.form.costPerHour || !this.form.costPerHour || !this.form.laborerNumber) {
        return 0
      }
      const costPerHour = parseFloat(this.form.costPerHour)
      const duration = parseFloat(this.shiftHoursFloat)
      const tax = (this.calculateTax(costPerHour))
      const unit = parseFloat((costPerHour + tax + this.calculateGain(1)).toFixed(2))
      const expense = (unit * duration) * parseInt(this.form.laborerNumber)
      return (expense * this.getWorkDays).toFixed(2)
    },
    isFormValid () {
      const hasBreak = this.lunchBreak !== ''
      const hasOffers = !this.requiredOffers
      const hasAmount = this.calcDifferencePrice <= 0
      const hasStartPeriod = !!this.startPeriod
      const hasEndPeriod = !!this.endPeriod || !this.isPeriod
      const hasShiftHours = !!this.shiftHoursFloat && this.shiftHoursFloat >= this.getRelayMinDuration
      // const hasStartDate= !!this.startDate
      // const hasEndDate= !!this.endDate
      const requiredExecutor = this.form.executorAssignmentType &&
        this.form.executorAssignmentType.id === ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE

      const {
        vacancySelected,
        laborerNumber,
        executorAssignmentType,
        costPerHour,
        selfEmployerCostPerHour,
        temperature,
        executor,
      } = this.form

      const executorValid = !requiredExecutor ||
        (!!requiredExecutor && !!executor && !!executor.id && !executor.isBlackListed && !executor.activeRelay)

      // const arr = {
      //   hasBreak,
      //   hasOffers,
      //   hasAmount,
      //   executorAssignmentType,
      //   vacancySelected: !!vacancySelected,
      //   laborerNumber,
      //   costPerHour,
      //   hasEndPeriod,
      //   hasShiftHours,
      //   hasStartPeriod,
      //   executorValid,
      //   selfEmployerCostPerHour,
      //   cost: (!!costPerHour || !!selfEmployerCostPerHour),
      //   temperature
      // }
      // AppLogger.info(arr, 'VALIDITY')
      // AppLogger.info(111, 'VALIDITY')

      return !!hasBreak &&
        !!hasOffers &&
        !!hasAmount &&
        !!executorAssignmentType &&
        !!vacancySelected &&
        !!laborerNumber &&
        (!!costPerHour || !!selfEmployerCostPerHour) &&
        !!hasStartPeriod &&
        !!hasShiftHours &&
        !!hasEndPeriod &&
        !!executorValid &&
        !!temperature
    }
  },
  watch: {
    requiredOffers (val) {
      if (val) {
        this.errorDisplayed = true
        this.errorMessage = this.$t('offer.creation.offers_required')
      } else {
        this.errorDisplayed = false
        this.errorMessage = null
      }
    },
    shiftHours (val) {
      this.updateBreakValue(val)
    },
    isPeriod (val) {
      this.setDates(undefined, val)
    },
    'form.offerAccessType' (val) {
      // console.log(val, 'offerAccessType')
    },
    'form.gender' (val) {
      this.updateLaborerNumber()
    },
    'form.executorAssignmentType' (val) {
      this.updateLaborerNumber()
    },
    'form.vacancySelected' (val) {
      if (val) {
        const { regionSetting } = val
        if (regionSetting.length) {
          const regionData = regionSetting[0]

          this.form.costPerHour = regionData.payPerHour
          this.form.selfEmployerCostPerHour = regionData.selfEmployerPay
          // if (regionSetting[0].minPay < regionSetting[0].maxPay) {
          //   this.form.maxPay = regionSetting[0].maxPay
          // }

          if (regionSetting[0].hasFixedPay) {
            this.form.minPay = regionData.payPerHour
            this.form.maxPay = regionData.payPerHour
            this.form.minSelfEmployerPay = regionData.selfEmployerPay
            this.form.maxSelfEmployerPay = regionData.selfEmployerPay
          } else {
            this.form.minPay = regionData.minPay
            this.form.maxPay = regionData.maxPay
            this.form.minSelfEmployerPay = regionData.minSelfEmployerPay || regionData.hourlyPay
            this.form.maxSelfEmployerPay = regionData.maxSelfEmployerPay || regionData.selfEmployerPay
          }
        }
      }
    },
  },
  created () {
    this.shiftHours = `${this.getRelayMinDuration}:00`
    this.minRelayDuration = this.getRelayMinDuration
    this.maxRelayDuration = this.getRelayMaxDuration
    this.minBreakDuration = this.getLunchMinValue
    this.maxBreakDuration = this.getLunchMaxValue
    this.minStartDateInterval = this.getMinStartDateInterval
    this.setDates()

    // const legalFields = [
    //   {
    //     text: '', valid: false,
    //     validator: (value) => {
    //       return (
    //         parseFloat(value) >= parseFloat(this.form.minPay) &&
    //         parseFloat(value) <= parseFloat(this.form.maxPay)
    //       )
    //     }
    //   },
    //   { text: '', valid: false, validator: isNonNull },
    // ]
    // const selfEmploymentFields = [
    //   {
    //     text: '', valid: false,
    //     validator: (value) => {
    //       return (
    //         parseFloat(value) >= parseFloat(this.form.minSelfEmployerPay) &&
    //         parseFloat(value) <= parseFloat(this.form.maxSelfEmployerPay)
    //       )
    //     }
    //   },
    //   { text: '', valid: false, validator: isNonNull },
    // ]
    // const temperatureFields = [
    //   { text: '', valid: true, validator: isNonNull }
    // ]
    // const vacancySelected = [
    //   { text: '', valid: false, validator: isNonNull }
    // ]
    //
    // if (this.hasWorkerType) {
    //   this.validationFields = {
    //     vacancySelected,
    //     temperatureFields,
    //   }
    // } else {
    //   this.validationFields = {
    //     vacancySelected,
    //     temperatureFields,
    //     legalFields,
    //     selfEmploymentFields,
    //   }
    // }
  },
  methods: {
    updateLaborerNumber () {
      if (this.isPersonal) {
        this.form.laborerNumber = 1
        return
      }

      if (this.isRemoteTeam && this.form.laborerNumber > this.getPrivateAvailableWorkerNumber) {
        this.form.laborerNumber = this.getPrivateAvailableWorkerNumber
        return
      }
      if (this.isFavoriteTeam && this.form.laborerNumber > this.getFavoriteTeamAvailableWorkerNumber) {
        this.form.laborerNumber = this.getFavoriteTeamAvailableWorkerNumber
        return
      }

      this.form.laborerNumber = 1
    },
    mapShiftDurationInMinutes (val) {
      if (!val) {
        return 0
      }
      const [hoursString, minutesString] = val.split(':')
      const hours = hoursString ? parseInt(hoursString, 10) : 0
      const minutes = minutesString ? parseInt(minutesString, 10) : 0
      let durationInMinutes = 0
      if (hours > 0) {
        durationInMinutes = minutes + (hours * 60)
      }
      return durationInMinutes
    },
    onRefillOffer () {
      this.$emit('close')
      this.$emit('addBalance')
    },
    changeCountEmpLoyee (value) {
      const next = this.form.laborerNumber + value

      if (this.isPersonal || next < 1) {
        return
      }

      if (this.isFavoriteTeam && next > this.getFavoriteTeamAvailableWorkerNumber) {
        return
      }
      if (this.isRemoteTeam && next > this.getPrivateAvailableWorkerNumber) {
        return
      }

      if ((value === -1 && this.form.laborerNumber > 1) || (value === 1)) {
        this.form.laborerNumber += value
      }
    },

    async submitForm () {
      const officeId = this.$store.state.office.userOffice.id
      const {
        vacancySelected,
        temperature,
        hasTransportation,
        freeFood,
        laborerNumber,
        selfEmployerCostPerHour,
        costPerHour,
        gender,
      } = this.form

      const weatherCondition = this.$store.getters['temperature/findTemperatureByName'](temperature)

      // даты начала
      const localeFromDate = this.$moment(this.startPeriod)
      localeFromDate.set({
        hour: parseInt(this.$moment(this.startDate).hour()),
        minute: parseInt(this.$moment(this.startDate).minutes()),
        second: 0,
        millisecond: 0
      })

      const fromDate = localeFromDate.clone().utc()
      const offerStartDate = fromDate.utc()
      const startPeriod = this.isPeriod ? fromDate.clone() : fromDate

      const [hours, minutes] = this.shiftHours.split(':')
      const lunchBreakMinutes = this.lunchBreak ? parseInt(this.lunchBreak) : 0

      const toDate = localeFromDate.clone()
        .add(parseInt(hours), 'hours')
        .add(parseInt(minutes), 'minutes')

      const offerLocaleEndDate = toDate.add(lunchBreakMinutes, 'minutes')
      const offerEndDate = offerLocaleEndDate.clone().utc()

      let localeEndPeriod

      // const localeEndPeriod = this.isPeriod ? this.$moment(this.endPeriod).set({
      //   hour: localeFromDate.hour(),
      //   minute: localeFromDate.minutes(),
      //   second: 0,
      //   millisecond: 0
      // }) : toDate

      if (this.isPeriod) {
        localeEndPeriod = this.$moment(this.endPeriod).set({
          hour: localeFromDate.hour(),
          minute: localeFromDate.minutes(),
          second: 0,
          millisecond: 0
        }).add(parseInt(hours), 'hours')
          .add(parseInt(minutes + lunchBreakMinutes), 'minutes')
      } else {
        localeEndPeriod = toDate
      }

      const endPeriod = localeEndPeriod.clone().utc()
      const vaccine = this.vaccineRequirement.value

      const localeStartDate = localeFromDate.format(this.localFormat)
      const localeEndDate = offerLocaleEndDate.format(this.localFormat)

      const accessSettings = this.getOfferAccessSettings(this.form.executorAssignmentType.id)

      try {
        const minStart = this.$moment().add(10, 'minutes')
        const checkStartTime = this.$moment().utc().isSame(startPeriod, 'day')
        const payloadData = {
          'requiredVaccination': (vaccine && vaccine === REQUIRED_QR_CODE_ID),
          'vacancy': { id: vacancySelected.id },
          'isMulti': (this.isPeriod),
          'startDate': offerStartDate.format(this.localFormat),
          'endDate': offerEndDate.format(this.localFormat),
          'localeStartDate': localeStartDate,
          'localeEndDate': localeEndDate,
          'targetGender': gender === '-1' ? null : gender,
          'duration': this.shiftHoursFloat,
          'weatherCondition': { id: weatherCondition.id, title: weatherCondition.title },
          hasTransportation, freeFood,
          laborerNumber,
          'costPerHour': parseInt(costPerHour),
          'break': { duration: this.lunchBreak },
          'office': { id: officeId },
          'startPeriod': startPeriod.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          }).format(this.localFormat),
          'workDays': this.getWorkDays,
          'endPeriod': endPeriod.set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          }).format(this.localFormat),
          'selfEmployerCostPerHour': parseFloat(`${selfEmployerCostPerHour}`),
          ...accessSettings,
        }

        if (this.form.executor) {
          payloadData.laborer = { id: this.form.executor.id }
        }

        if (!checkStartTime || (checkStartTime && localeFromDate.isAfter(minStart))) {
          this.isLoading = true
          await this.$store.dispatch('offer/fetchOffersCreate', payloadData)
          this.isLoading = false
          this.$emit('buyOffer')
          this.$emit('close')
        } else {
          this.errorDisplayed = true
          this.errorMessage = 'Дата начала смены не может быть раньше чем за 10 мин после его создания'
          setTimeout(() => {
            this.clearFormError()
          }, 10000)
        }

        this.$store.commit('offer/clearOfficeItems')
        this.$router.push({
          name: routerList.OFFICE_OFFER_LIST_NAME,
          params: { id: this.getActiveOffice.id },
        })
      } catch (e) {
        let errorData = e.data
        console.log(e)
        if (typeof errorData === 'object') {
          errorData = JSON.stringify(errorData)
        }
        const { executor } = this.form

        if (executor && executor.id) {
          const laborerIsBlocked = errorData.indexOf('LaborerIsBlackListedException')
          const laborerIsOnBoarded = errorData.indexOf('LaborerIsBoardedException')
          if (laborerIsBlocked >= 0) {
            // laborer is blocked
            this.form = {
              ...this.form,
              executor: {
                ...this.form.executor,
                activeRelay: null,
                isBlackListed: true,
              }
            }
            this.eventsBus.$emit(
              this.eventNames.ON_OFFER_EXECUTOR_BLACKLISTED_UPDATED,
              { isBlackListed: true, activeRelay: null }
            )

            this.errorMessage = 'Выбранный вами пользователь заблокирован. Обратитесь в поддержку сервиса'
          } else if (laborerIsOnBoarded > 0) {
            this.form = {
              ...this.form,
              executor: {
                ...this.form.executor,
                activeRelay: true,
                isBlackListed: null,
              }
            }
            this.eventsBus.$emit(
              this.eventNames.ON_OFFER_EXECUTOR_BLACKLISTED_UPDATED,
              { isBlackListed: null, activeRelay: true }
            )
            this.errorMessage = 'Выбранный вами пользователь в это время на другой смене'
          }
        } else {
          this.errorMessage = 'Ошибка при выполнении запроса! попробуйте еще раз или обратитесь в поддержку сервиса'
        }
        // 79132065714
        this.isLoading = false
        this.errorDisplayed = true
        setTimeout(() => {
          this.clearFormError()
        }, 10000)
      }
    },
    clearFormError () {
      this.errorDisplayed = false
      this.errorMessage = null
    },
    getOfferAccessSettings (selectedOption) {
      switch (selectedOption) {
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_MANUAL,
          executorGroupType: ALIAS_OEC_LABORER
        }
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_PRIVATE:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          executorGroupType: ALIAS_OEC_PERSONAL
        }
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_FAVORITE_LIST:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          executorGroupType: ALIAS_OEC_FAVORITE
        }
      case ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          executorGroupType: ALIAS_OEC_LABORER
        }
      case ALIAS_VACANCY_TYPE_INNER_WORKER:
        return {
          executorAssignmentType: ALIAS_OFFER_EXECUTOR_ASSIGNMENT_TYPE_AUTO,
          executorGroupType: ALIAS_OEC_REMOTE_STAFF
        }
      }
    },
    updateBreakValue (shiftDuration) {
      if (this.hasLunchBreakSettingMatrix) {
        const getBreakValue = (duration) => {
          const matrix = this.getLunchBreakSetting

          let prevVal = null
          // eslint-disable-next-line guard-for-in
          for (const ind in matrix) {
            const value = matrix[ind]
            const key = parseFloat(ind)

            if (duration < key && prevVal === null) {
              return 0
            }
            if (duration === key) {
              return value
            } else if (key > duration) {
              return prevVal
            }
            prevVal = value
          }

          // когда только одно значение
          if (prevVal && duration > prevVal) {
            return prevVal
          }
          return 0
        }
        const duration = this.mapShiftDurationInMinutes(shiftDuration)
        const breakDuration = getBreakValue(duration)
        this.lunchBreak = breakDuration
      }
    },

    onDateChange (nextStartDate) {
      this.shiftHours = `${this.getRelayMinDuration}:00`
      this.setDates(nextStartDate)
    },
    onCalendarModeChange (val) {
      this.setDates(undefined, val)
    },
    goToSupportPage () {
      this.$router.push({ name: routerList.USER_SUPPORT_ROUTE_NAME })
    },
    setDates (val, period) {
      const isSameDay = !val || (val && this.$moment().isSame(val, 'day'))
      if (period === false || period === true) {
        this.lunchBreak = ''
      }
      if (!isSameDay || period === true) {
        this.shiftHours = `${this.getRelayMinDuration}:00`
      }

      if (val && period) {
        // const now = this.$moment()
        const now = val
        now
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          // .add(1, 'days')
        this.minStartDate = now
        this.startDate = now.toISOString()
      } else if (period) {
        const now = this.$moment()
        now
          .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
          .add(1, 'days')
        this.minStartDate = now
        this.startDate = now.toISOString()
      } else {
        this.minStartDate = this.$moment().add(this.minStartDateInterval, 'minutes')
        const minutes = parseInt(this.minStartDate.format('mm'), 10)
        const hours = parseInt(this.minStartDate.format('HH'), 10)


        let nextMinutes = minutes
        let nextHours = hours

        if (isSameDay) {
          if (minutes >= 45 && minutes <= 59) {
            nextMinutes = 45
          } else if (minutes >= 30 && minutes < 45) {
            nextMinutes = 30
          } else if (minutes >= 15 && minutes < 30) {
            nextMinutes = 15
          } else {
            nextMinutes = 15
          }
        } else {
          nextMinutes = 0
          nextHours = 0
        }

        this.minStartDate
          .set({ hour: nextHours, minutes: nextMinutes, second: 0, millisecond: 0 })

        this.startDate = this.minStartDate.toISOString()
      }
      this.endDate = this.$moment(this.startDate).add(this.shiftHoursFloat, 'hours').toISOString()
      this.updateBreakValue(this.shiftHours)
    },
  }
}
</script>

<style lang="sass" scoped>
@import '@/assets/sass/create-offer-modal'
</style>

<style lang="sass">
.selector
  .input-date-wide
    width: 45px

.floating-notification
  @include fontStyle(800, 14px)
  color: white
  background: #4B4B4B
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1)
  border-radius: 6px
  padding: 8px
  position: absolute
  right: 15px
  bottom: 15%
  min-width: 100px
  max-width: 240px
</style>

